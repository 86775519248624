import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import Footer from './Footer';
import './ChannelList.css';

const ChannelList = () => {
  const [channels, setChannels] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [moreAvailable, setMoreAvailable] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchChannels();
  }, []);

  const fetchChannels = async () => {
    const channelCollectionRef = collection(db, "channels");
    const q = query(channelCollectionRef, orderBy("id"), limit(50));
    const data = await getDocs(q);
    const lastVisibleDocument = data.docs[data.docs.length - 1];
    setChannels(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    setLastVisible(lastVisibleDocument);
    setMoreAvailable(data.docs.length === 50);
  };

  const loadMoreChannels = async () => {
    if (lastVisible) {
      const channelCollectionRef = collection(db, "channels");
      const q = query(channelCollectionRef, orderBy("id"), startAfter(lastVisible), limit(50));
      const data = await getDocs(q);
      const lastVisibleDocument = data.docs[data.docs.length - 1];
      setChannels(prevChannels => [...prevChannels, ...data.docs.map(doc => ({ ...doc.data(), id: doc.id }))]);
      setLastVisible(lastVisibleDocument);
      setMoreAvailable(data.docs.length === 50);
    }
  };

  return (
    <div className="channelList">
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />

      <h1>Channels</h1>
      <div className="channel-list-grid">
        {channels.map(channel => (
          <div key={channel.id} className="channel-list-item" onClick={() => navigate(`/channel/${channel.id}`)}>
            <img src={channel.profilePictureUrl || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt={channel.actualTitle || "Unnamed Channel"} />
            <span>{channel.actualTitle || "Unnamed Channel"}</span>
          </div>
        ))}
      </div>

      {moreAvailable && (
        <button onClick={loadMoreChannels} className="load-more-btn">
          Load More
        </button>
      )}

      <Footer />
    </div>
  );
};

export default ChannelList;
