import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import Footer from './Footer';
import './TopicList.css';

const TopicList = () => {
  // const [channels, setChannels] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  // const fetchChannels = async () => {
  // const channelCollectionRef = collection(db, "channels");
  // const data = await getDocs(channelCollectionRef);
  // setChannels(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
  // };

  // fetchChannels();
  // }, []);

  return (
    <div className="topic-list">
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />

      <h1>Topics</h1>
      <div className="topic-list-grid">

      <div className="topic-list-item" onClick={() => navigate(`/topics/calisthenics`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/5845/5845581.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Bodyweight Fitness </div>
        {/* / Calisthenics */}
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/math`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/4/4363.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Mathematics</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/physics`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/1125/1125008.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Physics</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/climbing`)}>
          <img src="https://cdn-icons-png.flaticon.com/512/114/114434.png" alt="" className="topic-icon" />
          <div class="topic-list-title">Climbing</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/nba`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/3349/3349343.png" alt="" className="topic-icon" />
        <div class="topic-list-title">NBA</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/running`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/233/233146.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Running</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/swimming`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/1401/1401499.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Swimming</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/ai`)}>
          <img src="https://cdn-icons-png.flaticon.com/512/826/826118.png" alt="" className="topic-icon" />
          <div class="topic-list-title">Artificial Intelligence</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/cooking`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/9173/9173972.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Cooking</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/wine`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/701/701986.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Wine</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/history`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/3079/3079186.png" alt="" className="topic-icon" />
        <div class="topic-list-title">History</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/nutrition`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/780/780109.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Nutrition</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/biology`)}>
        <img src="https://cdn-icons-png.flaticon.com/512/1651/1651694.png" alt="" className="topic-icon" />
        <div class="topic-list-title">Biology</div>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/gardening`)}>
          <span>Gardening (soon)</span>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/books`)}>
          <span>Books (soon)</span>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/art`)}>
          <span>Art (soon)</span>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/fitness`)}>
          <span>Fitness (soon)</span>
        </div>

        <div className="topic-list-item" onClick={() => navigate(`/topics/`)}>
          <span>Many coming soon!</span>
        </div>

      </div>

      <Footer />
    </div>
  );
};

export default TopicList;