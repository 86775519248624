import axios from 'axios';

export async function getYoutubeChannelInfo(channelName) {
  const apiKey = 'AIzaSyD6PuHeqh2m2XbUWJbG7r0AGHfzQxPrnuY';
  const searchUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${channelName}&type=channel&key=${apiKey}`;

  // const searchUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${encodeURIComponent(channelName)}&type=channel&key=${apiKey}`;

  try {
    // console.log("CALLING API")
    // throw new Error('No channel found'); // testing mode to not hit the quota

    // Search for the channel by name to get the channel ID
    const searchResponse = await axios.get(searchUrl);
    const channels = searchResponse.data.items;
    if (channels.length === 0) {
      throw new Error('No channel found');
    }

    // Look for an exact match (case-insensitive) in the search results
    const exactMatchChannel = channels.find(channel => 
      channel.snippet.channelTitle.toLowerCase() === channelName.toLowerCase()
    );

    if (!exactMatchChannel) {
      throw new Error('No exact match found');
    }

    const channelId = exactMatchChannel.id.channelId;

    // Now use the channel ID to fetch detailed information, including statistics
    const channelDetailsUrl = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=${apiKey}`;
    const detailsResponse = await axios.get(channelDetailsUrl);
    if (detailsResponse.data.items.length === 0) {
      throw new Error('Channel details not found');
    }
    const channelDetails = detailsResponse.data.items[0];

    return {
      description: channelDetails.snippet.description,
      profilePictureUrl: channelDetails.snippet.thumbnails.high.url,
      actualTitle: channelDetails.snippet.title,
      totalViews: channelDetails.statistics.viewCount,
      subscriberCount: channelDetails.statistics.subscriberCount,
      videoCount: channelDetails.statistics.videoCount,
      creationDate: channelDetails.snippet.publishedAt,
      id: channelId,
      customUrl: channelDetails.snippet.customUrl ?? "",
    };
  } catch (error) {

    // return {
    //   description: "test channel",
    //   profilePictureUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt5h5Y3hJ1lM0jLirxF-FFUNnYD2dsn1ym5gATIynuZQ&s",
    //   actualTitle: channelName,
    //   totalViews: 100,
    //   subscriberCount: 8,
    //   videoCount: 11,
    //   creationDate: "april 2012",
    //   id: channelName
    // };

    console.error('Error fetching channel data:', error);
    return null;
    // return 'Error fetching channel data';
  }
}