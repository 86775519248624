import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db, auth, provider } from '../firebase';
import TopBar from './TopBar';
import './UserPage.css'; // Ensure CSS styles are updated accordingly
import Footer from './Footer';
import Review from './Review';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getSubscribedChannels, getLikedVideos, getAllStoredVideos } from '../api/subscriptions';
import { collection, getDocs, getCountFromServer, query, orderBy, limit, startAfter } from 'firebase/firestore';

const UserPage = () => {
  const { userName } = useParams();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditBio, setShowEditBio] = useState(false);
  const [editBio, setEditBio] = useState(""); // To store the edited bio
  const [isFollowing, setIsFollowing] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [likedVideos, setLikedVideos] = useState([]);
  const [showLikedVideos, setShowLikedVideos] = useState(false);
  const [isLoadingVideos, setIsLoadingVideos] = useState(false);
  const [loadedVideoCount, setLoadedVideoCount] = useState(0);
  const [storedLikedVideos, setStoredLikedVideos] = useState([]);
  const [likedVideosCount, setLikedVideosCount] = useState(0);
  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const VIDEOS_PER_PAGE = 100;

  useEffect(() => {
    const fetchUserData = async () => {
      const userDocRef = doc(db, "users", userName);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserData(userDoc.data());
        setEditBio(userDoc.data().bio); // Initialize editBio with the current bio
        // Check if current user is following this user
        if (auth.currentUser) {
          setIsFollowing(userDoc.data().followers?.includes(auth.currentUser.uid) || false);
        }
      } else {
        setUserData(null);
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [userName]);

  useEffect(() => {
    const loadInitialVideos = async () => {
      if (userName) {
        const videosRef = collection(db, "users", userName, "likedVideos");

        // Get total count
        const snapshot = await getCountFromServer(videosRef);
        setLikedVideosCount(snapshot.data().count);

        // Get first batch
        const q = query(
          videosRef,
          orderBy("position", "asc"),
          limit(VIDEOS_PER_PAGE)
        );

        const querySnapshot = await getDocs(q);
        const videos = [];
        querySnapshot.forEach(doc => {
          videos.push(doc.data());
        });

        setDisplayedVideos(videos);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === VIDEOS_PER_PAGE);
      }
    };

    loadInitialVideos();
  }, [userName]);

  const handleEditBio = () => {
    setShowEditBio(true); // Show the input field for editing bio
  };

  const handleBioSubmit = async () => {
    if (auth.currentUser && userName === auth.currentUser.uid) {
      await updateDoc(doc(db, "users", userName), {
        bio: editBio,
      });
      setShowEditBio(false); // Hide the input field after submitting
      // Optionally, fetch user data again to update the UI
      setUserData(prevState => ({
        ...prevState,
        bio: editBio,
      }));
    }
  };

  const handleFollowToggle = async () => {
    if (!auth.currentUser) return; // Exit if not logged in

    const currentUserRef = doc(db, "users", auth.currentUser.uid);
    const targetUserRef = doc(db, "users", userName);

    try {
      if (isFollowing) {
        // Unfollow
        await updateDoc(targetUserRef, {
          followers: arrayRemove(auth.currentUser.uid)
        });
        await updateDoc(currentUserRef, {
          following: arrayRemove(userName)
        });
      } else {
        // Follow
        await updateDoc(targetUserRef, {
          followers: arrayUnion(auth.currentUser.uid)
        });
        await updateDoc(currentUserRef, {
          following: arrayUnion(userName)
        });
      }
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error("Error updating follow status:", error);
    }
  };

  const handleShowSubscriptions = async () => {
    try {
      const channelsList = await getSubscribedChannels();
      setSubscriptions(channelsList);
      setShowSubscriptions(true);
    } catch (error) {
      console.error("Error loading subscriptions:", error);
    }
  };

  const handleShowLikedVideos = async () => {
    try {
      setIsLoadingVideos(true);
      const videos = await getLikedVideos();
      setLikedVideos(videos);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingVideos(false);
    }
  };

  // To read stored videos later:
  const loadStoredVideos = async () => {
    if (auth.currentUser) {
      const videos = await getAllStoredVideos(auth.currentUser.uid);
      setLikedVideos(videos);
    }
  };

  const loadMoreVideos = async () => {
    if (!hasMore || isLoadingMore) return;

    try {
      setIsLoadingMore(true);
      const videosRef = collection(db, "users", userName, "likedVideos");

      const q = query(
        videosRef,
        orderBy("position", "asc"),
        startAfter(lastVisible),
        limit(VIDEOS_PER_PAGE)
      );

      const querySnapshot = await getDocs(q);
      const newVideos = [];
      querySnapshot.forEach(doc => {
        newVideos.push(doc.data());
      });

      setDisplayedVideos(prev => [...prev, ...newVideos]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === VIDEOS_PER_PAGE);
    } catch (error) {
      console.error("Error loading more videos:", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="userpage">
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />

      {userData ? (
        <div className="user-all">
          <div className="user-content">
            <div className="user-details">
              <div className="profile-and-name">
                <div className="user-name">{userData.displayName}</div>
                <img src={userData.photoURL || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt="User profile" />
              </div>
              <div className="user-subtitle">user</div>
              <div className="bio">{userData.bio || "No bio available."}</div>
              {auth.currentUser && userName === auth.currentUser.uid ? (
                !showEditBio && <button onClick={handleEditBio}>Edit Bio</button>
              ) : (
                auth.currentUser && (
                  <button
                    onClick={handleFollowToggle}
                    className={`follow-button ${isFollowing ? 'following' : ''}`}
                  >
                    {isFollowing ? 'Unfollow' : 'Follow'}
                  </button>
                )
              )}
              {showEditBio && (
                <div className="review-popup" style={{ padding: '20px', maxWidth: '600px' }}>
                  <textarea
                    className="edit-bio-textarea"
                    value={editBio}
                    onChange={(e) => setEditBio(e.target.value)}
                    style={{ margin: '10px 0', padding: '10px', height: '100px' }}
                  />
                  <button className="submit-bio-button" onClick={handleBioSubmit} style={{ marginTop: '10px' }}>
                    Submit
                  </button>
                </div>
              )}
            </div>

            <div className="user-stats">
              <br></br>
              <div className="follow-stats">
                <p>
                  <strong>{userData.followers?.length || 0}</strong> followers ·{' '}
                  <strong>{userData.following?.length || 0}</strong> following
                </p>
              </div>
              <div>
                <p>Number of Ratings: {userData.numRated}</p>
              </div>
              <br></br>

              <div className="ratings-distribution">
                {
                  ['S', 'A', 'B', 'C', 'D', 'F'].map((key) => {
                    const totalRatings = Object.values(userData?.ratingsCount || {}).reduce((acc, value) => acc + value, 0);
                    const ratingValue = userData?.ratingsCount[key] || 0;
                    const percentage = totalRatings > 0 ? (ratingValue / totalRatings) * 100 : 0;

                    return (
                      <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <span style={{ width: '20px', marginRight: '10px' }}>{key}</span>
                        <div style={{ background: '#e0e0e0', width: '100%', marginRight: '10px', display: 'flex', height: '20px' }}>
                          <div style={{ background: '#6DD3CE', width: `${percentage + 1}%` }}></div>
                        </div>
                        <span>{ratingValue}</span>
                      </div>
                    );
                  })
                }
              </div>

              {auth.currentUser && userName === auth.currentUser.uid && (
                <>
                  {/* <button className="user-button" onClick={handleShowSubscriptions}>
                    Show YouTube Subscriptions
                  </button> */}

                  {/* TODO: Add this back in potentially, if quota gets fine */}
                  {/* <br></br>
                  <button className="user-button"
                    onClick={handleShowLikedVideos}
                    disabled={isLoadingVideos}
                  >
                    {isLoadingVideos
                      ? `Loading...`
                      : 'Import Liked Videos'}
                  </button> */}

                  {showSubscriptions && (
                    <div className="subscriptions-list">
                      <h3>Subscribed Channels:</h3>
                      {subscriptions.length > 0 ? (
                        <ul>
                          {subscriptions.map((channel) => (
                            <li key={channel.channelId}>
                              <img
                                src={channel.thumbnails.default.url}
                                alt={channel.title}
                                width="88"
                                height="88"
                              />
                              <h4>{channel.title}</h4>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No subscribed channels found.</p>
                      )}
                    </div>
                  )}

                  {showLikedVideos && (
                    <div className="liked-videos-list">
                      <h3>Liked Videos:</h3>
                      {likedVideos.length > 0 ? (
                        <ul>
                          {likedVideos.map((video) => (
                            <li key={video.videoId}>
                              <img
                                src={video.thumbnails.default.url}
                                alt={video.title}
                                width="120"
                                height="90"
                              />
                              <div>
                                <h4>{video.title}</h4>
                                <p>{video.videoId}</p>
                                <p>{video.channelTitle}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No liked videos found.</p>
                      )}
                    </div>
                  )}
                </>
              )}

            </div>
          </div>

          <div className="user-reviews">
            <h2>Channel Reviews</h2>
            {userData.reviews && userData.reviews.length > 0 ? (
              <ul className="reviews-list">
                {userData.reviews.map((review, index) => (
                  <Review
                    key={index}
                    rating={review.rating}
                    text={review.text}
                    channel={review.channelName}
                    user={userData.displayName}
                    linkTo={`/channel/${review.channelName}`}
                  />
                ))}
              </ul>
            ) : (
              <p>No reviews yet.</p>
            )}

            {auth.currentUser && userName === auth.currentUser.uid && (
              <>
                <br></br>
                <h2>Liked Videos ({likedVideosCount})</h2>
                {displayedVideos.length > 0 ? (
                  <>
                    <ul className="liked-videos-list">
                      {displayedVideos.map((video) => (
                        <li key={video.videoId} className="liked-video-item">
                          <div className="liked-video-thumbnail">
                            <Link to={`/video/${video.videoId}`} target="_blank" rel="noopener noreferrer">
                              <img
                                src={video.thumbnail}
                                alt={video.title}
                                width="120"
                                height="90"
                              />
                            </Link>
                          </div>
                          <div className="video-details">
                            <h3 className="liked-video-title">
                              <Link to={`/video/${video.videoId}`} target="_blank" rel="noopener noreferrer">
                                {video.title}
                              </Link>
                            </h3>
                            <div className="channel-title">
                              <Link to={`/channel/${video.channelTitle}`} target="_blank" rel="noopener noreferrer">
                                {video.channelTitle}
                              </Link>
                            </div>
                            {/* <p className="video-date">
                          Added: {new Date(video.publishedAt).toLocaleDateString()}
                        </p> */}
                          </div>
                        </li>
                      ))}
                    </ul>
                    {hasMore && (
                      <button
                        onClick={loadMoreVideos}
                        disabled={isLoadingMore}
                        className="load-more-button"
                      >
                        {isLoadingMore ? 'Loading...' : 'Load More Videos'}
                      </button>
                    )}
                  </>
                ) : (
                  <p>No liked videos found.</p>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="user-not-found">
          <h1>User not found</h1>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default UserPage;
