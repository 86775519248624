import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import Homepage from './Homepage';
import ChannelPage from './ChannelPage';
import ChannelList from './ChannelList';
import UserList from './UserList';
import UserPage from './UserPage';
import VideoPage from './VideoPage';
import VideoList from './VideoList';
import TopicList from './TopicList';
import TopicPage from './TopicPage';
import Lab from './Lab';
import Edu from './Edu';
import Login from './Login'; // Assume you have a LoginPage component
import Terms from './Terms';
import Privacy from './Privacy';
import YouTubeWrapped from './YouTubeWrapped';

import ClimbingPage from './ClimbingPage';

function WatchRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const videoId = params.get('v');
    if (videoId) {
      navigate(`/video/${videoId.substring(0, 11)}`);
    } else {
      navigate('/');
    }
  }, [location, navigate]);

  return null; // This component doesn't render anything itself
}

function Main() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false); // Set loading to false after checking auth state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Show a loading indicator or return null while checking user auth state
  if (loading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (

    <Router>
      <Routes>
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route path="/login" element={currentUser ? <Navigate to="/" /> : <Login />} />

        <Route path="/" element={currentUser ? <Homepage /> : <Navigate to="/login" />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/channel/:channelName" element={<ChannelPage />} />
        <Route path="/user/:userName" element={<UserPage />} />
        <Route path="/video/:videoId" element={<VideoPage />} />
        <Route path="/channels" element={<ChannelList />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/videos" element={<VideoList />} />
        <Route path="/lab" element={<Lab />} />
        <Route path="/edu" element={<Edu />} />

        {/* <Route path="/topics/climbing" element={<ClimbingPage />} /> */}
        <Route path="/topics" element={<TopicList />} />
        <Route path="/topics/:topicId" element={<TopicPage />} />

        <Route path="/watch" element={<WatchRedirect />} />
        <Route path="/wrapped" element={<YouTubeWrapped />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default Main;
