import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";
// import { useNavigate } from 'react-router-dom';

export const firebaseConfig = {
    // apiKey: "AIzaSyDMKZWT0iRCPVeDyYNUY2fK7br_YiEtHlw",
    // authDomain: "stierstuff.firebaseapp.com",
    // projectId: "stierstuff",
    // storageBucket: "stierstuff.appspot.com",
    // messagingSenderId: "682482623509",
    // appId: "1:682482623509:web:56e1ca38acde675c199a1c",
    // measurementId: "G-S5K23BG8SE",
    apiKey: "AIzaSyDReNBMwOBYRsJzMEeLMtbgbvQIM5tKbp0",
    authDomain: "fir-tier-stuff.firebaseapp.com",
    projectId: "s-tier-stuff",
    storageBucket: "s-tier-stuff.firebasestorage.app",
    messagingSenderId: "450470291818",
    appId: "1:450470291818:web:4a35bb645d6ef35708dd13",
    measurementId: "G-15QHL1ETNV",
    clientId: "450470291818-h9bd51fnlhm06vcj1dq7tu6ivalpahcg.apps.googleusercontent.com",
    oauth2Config: {
        clientId: "450470291818-h9bd51fnlhm06vcj1dq7tu6ivalpahcg.apps.googleusercontent.com",
        scopes: ["https://www.googleapis.com/auth/youtube.readonly"],
        // Make sure these match your Google Cloud Console settings exactly
      }
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);

export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        // Reference to the user's document in the 'users' collection
        const userDocRef = doc(db, "users", user.uid);

        // Check if the user's document exists
        const docSnap = await getDoc(userDocRef);
        if (!docSnap.exists()) {
            // User document does not exist, create a new one
            await setDoc(userDocRef, {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                following: [], // Empty array for users the user is following
                followers: [], // Empty array for users following this user
                channels: [],  // Empty array for channels
                numRated: 0,   // Initialize number rated count
                numReviewed: 0, // Initialize number reviewed count
                ratingsCount: {
                    S: 0,
                    A: 0,
                    B: 0,
                    C: 0,
                    D: 0,
                    F: 0,
                  },
                bio: "",
            });
        }
    } catch (err) {
        console.error(err);
    }
}

export const logOut = async () => {
    try {
        await signOut(auth);
    } catch (err) {
        console.error(err);
    }
}

export default app;

// firebase.js

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);