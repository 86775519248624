import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import Footer from './Footer';
import './VideoList.css';

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [moreAvailable, setMoreAvailable] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchVideos().catch(console.error); // Error handling and console output for debugging
  }, []);

  const fetchVideos = async () => {
    try {
      const videoCollectionRef = collection(db, "videos");
      const q = query(videoCollectionRef, orderBy("likeCount"), limit(50)); // Assuming 'id' is a valid field for ordering
      const data = await getDocs(q);
      const lastVisibleDocument = data.docs[data.docs.length - 1];
      console.log("Fetched Videos:", data.docs.map(doc => doc.data())); // Debugging log
      setVideos(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      setLastVisible(lastVisibleDocument);
      setMoreAvailable(data.docs.length === 50);
    } catch (error) {
      console.error("Failed to fetch videos:", error);
    }
  };

  const loadMoreVideos = async () => {
    try {
      if (lastVisible && moreAvailable) {
        const videoCollectionRef = collection(db, "videos");
        const q = query(videoCollectionRef, orderBy("likeCount"), startAfter(lastVisible), limit(50));
        const data = await getDocs(q);
        const lastVisibleDocument = data.docs[data.docs.length - 1];
        console.log("Loaded More Videos:", data.docs.map(doc => doc.data())); // Debugging log
        setVideos(prevVideos => [...prevVideos, ...data.docs.map(doc => ({ ...doc.data(), id: doc.id }))]);
        setLastVisible(lastVisibleDocument);
        setMoreAvailable(data.docs.length === 50);
      }
    } catch (error) {
      console.error("Failed to load more videos:", error);
    }
  };

  return (
    <div className="videoList">
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />

      <h1>Videos</h1>
      <div className="video-list-grid">
        {videos.map(video => (
          <div key={video.id} className="video-list-item" onClick={() => navigate(`/video/${video.id}`)}>
            <img src={video.thumbnail?.url || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt={video.title || "Unnamed Video"} />
            <span>{video.title || "Unnamed Video"}</span>
          </div>
        ))}
      </div>

      {moreAvailable && (
        <button onClick={loadMoreVideos} className="load-more-btn">
          Load More
        </button>
      )}

      <Footer />
    </div>
  );
};

export default VideoList;
