import React from 'react';
// Import your images here
import homeIcon from '../home.png'; // Update path to your home icon image
import emailIcon from '../mail.png'; // Update path to your email icon image
import './Footer.css'; // Make sure the path matches your file structure

function Footer() {
  return (
    <footer>
      <a href="/" title="Go to Homepage">
        <img src={homeIcon} alt="Home" />
      </a>
      <a href="mailto:stierstuff@gmail.com" title="Send Email">
        <img src={emailIcon} alt="Email" />
      </a>
    </footer>
  );
}

export default Footer;