import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Review.css';

const Review = ({ rating, text, channel, user, linkTo }) => {
  const navigate = useNavigate();

  return (
    <div className="review" onClick={() => navigate(linkTo)}>
      <div className="review-rating">{rating}</div>
      <div className="review-content">
        <div className="review-description">
          {user} rated {channel}
        </div>
        <div className="review-text">
          {text}
        </div>
      </div>
    </div>
  );
};

export default Review;