import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../firebase";
import { query, getDocs, collection, orderBy, limit } from "firebase/firestore";
import './Homepage.css';
import TopBar from './TopBar';
import Footer from './Footer';
import Review from './Review';

const Homepage = () => {
  const [channelList, setChannelList] = useState([]);
  const [recentReviews, setRecentReviews] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [userName, setUserName] = useState('guest');

  useEffect(() => {
    if (auth.currentUser) {
      setUserName(auth.currentUser.displayName || 'user');
    }
    const getChannelList = async () => {
      const channelsQuery = query(collection(db, "channels"), orderBy("numberOfReviews", "desc"), limit(5));
      const querySnapshot = await getDocs(channelsQuery);

      const channels = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setChannelList(channels);
    };

    const fetchRecentReviews = async () => {
      const recentReviewsQuery = query(collection(db, "reviews"), orderBy("timestamp", "desc"), limit(10));
      const reviewQuerySnapshot = await getDocs(recentReviewsQuery);

      const reviews = reviewQuerySnapshot.docs.map(doc => doc.data());
      setRecentReviews(reviews);
    }

    const getVideoList = async () => {
      const videosQuery = query(collection(db, "videos"), orderBy("timestamp", "desc"), limit(5));
      const querySnapshot = await getDocs(videosQuery);

      const videos = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setVideoList(videos);
    }

    getChannelList();
    fetchRecentReviews();
    getVideoList();
  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/channel/${searchTerm.trim()}`);
    }
  };

  const [videoSearchTerm, setVideoSearchTerm] = useState('');
  const handleVideoSearchChange = (event) => {
    setVideoSearchTerm(event.target.value);
  };

  function extractVideoId(url) {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)?([a-zA-Z0-9_-]{11})/);
    return match && match[1];
  }

  const handleVideoSubmit = async (event) => {
    event.preventDefault();
    if (videoSearchTerm) {
      navigate(`/video/${extractVideoId(videoSearchTerm.trim())}`);
    }
  };

  return (
    <div className="homepage">
      <div className="top-bar">
        <TopBar
          title="S-TIER STUFF"
          profileImageUrl={auth?.currentUser?.photoURL}
        />
      </div>

      <div className="home-all">
        <div className="welcome">
          Welcome, {userName}!
        </div>

        <div className="searchbar">
          <form onSubmit={handleVideoSubmit}>
            <input
              className="input"
              type="text"
              placeholder="Search for a video"
              value={videoSearchTerm}
              onChange={handleVideoSearchChange}
            />
            <button type="submit">Search</button>
          </form>
          Search videos by their URL or Video ID
        </div>

        <div className="popular-videos">
          <h2>Recent Videos</h2>
          <div className="video-list">
            {videoList.map(video => (
              <div key={video.id} className="video-item" onClick={() => navigate(`/video/${video.id}`)}>
                <img src={video.thumbnail?.url || 'https://via.placeholder.com/150'} alt={video.title} className="video-thumbnail" />
                <p>{video.title}</p>
              </div>
            ))}
          </div>

        </div>

        <div className="searchbar">
          <form onSubmit={handleSearch}>
            <input
              className="input"
              type="text"
              placeholder="Search for a channel"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="submit">Search</button>
          </form>
          Search channels by their channel name (like J. Cole instead of @jcole)
        </div>

        <div className="popular-channels">
          <h2>Popular Channels</h2>
          <div className="channel-list">
            {channelList.map(channel => (
              <div key={channel.id} className="channel-item" onClick={() => navigate(`/channel/${channel.id}`)}>
                <img src={channel.profilePictureUrl || 'https://via.placeholder.com/150'} alt={channel.name} className="channel-profile-picture" />
                <p>{channel.actualTitle}</p>
              </div>
            ))}
          </div>
        </div>

        <h2>Recent Activity</h2>
        <div className="reviews-section">
          {recentReviews.length > 0 ? (
            <ul className="reviews-list">
              {recentReviews.map((review, index) => (
                <Review
                  key={index}
                  rating={review.rating}
                  text={review.text}
                  channel={review.channel}
                  user={review.displayName || 'Someone'}
                  linkTo={`/channel/${review.channel}`}
                />
              ))}
            </ul>
          ) : (
            <p>No reviews yet.</p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
