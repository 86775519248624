import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import Footer from './Footer';
import './UserList.css'; // Assume similar styling to ChannelList.css

const UserList = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const userCollectionRef = collection(db, "users");
      const data = await getDocs(userCollectionRef);
      setUsers(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchUsers();
  }, []);

  return (
    <div className="userList">
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />

      <h1>Users</h1>
      <div className="user-list-grid">
        {users.map(user => (
          <div key={user.id} className="user-list-item" onClick={() => navigate(`/user/${user.id}`)}>
            <img src={user.photoURL || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt={user.displayName || "Unnamed User"} />
            <span>{user.displayName || "Unnamed User"}</span>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default UserList;
