import axios from 'axios';
import OpenAI from "openai";

// This function is hypothetical and should be executed in a server-side environment
// to keep your OpenAI API key secure.
async function analyzeComments(comments) {
  try {
    const openai = new OpenAI({
      apiKey: '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',
      dangerouslyAllowBrowser: true
    });

    const completion = await openai.chat.completions.create({
      messages: [{ "role": "system", "content": "You are a helpful assistant." },
      { "role": "user", "content": "This is what some people think of a video. Could you summarize their opinions? \n" + comments.join('\n') }],
      model: "gpt-3.5-turbo",
    });

    // console.log(completion);
    // console.log(completion.choices[0].message.content);

    const summary = completion.choices[0].message.content;

    const completion1 = await openai.chat.completions.create({
      messages: [{ "role": "system", "content": "You are a helpful assistant." },
      {
        "role": "user", "content":
          "This is what some people think of a video. Could you give a rating of the video based on their opinions? \
          \n" + comments.join('\n') + "The rating scale is as follows: \
          S means this is among the best videos people have seen in their lives. \
          A means this is an amazing video, most people are extremely excited about it and have really positive things to say \
          B means it's a good video. People generally like it and give supportive opinions. \
          C means it's an alright video. Some people like it but there are more mixed reviews. \
          D means it's generally a bad video. Most people are complaining or at least a majority seem to have negative opinions. \
          F means it's a terrible video and people generally dislike it or have bad things to say. \
          Most importantly, please respond with the rating and just the rating in bold, either S, A, B, C, D, or F. Thanks!"
      }],
      model: "gpt-3.5-turbo",
    });

    const finalResult = {
      summary: summary,
      rating: completion1.choices[0].message.content
    };

    return finalResult;

  } catch (error) {
    console.error('Error analyzing comments with OpenAI:', error);
    throw error; // Re-throw to handle it in the calling function
  }
}

export async function fetchCommentsAndAnalyze(videoUrl) {
  const apiKey = 'AIzaSyDgIvGLR_s05UroGW5CgdftmOcQ85H30So';
  const videoId = extractVideoId(videoUrl);
  const commentsUrl = `https://www.googleapis.com/youtube/v3/commentThreads?part=snippet&videoId=${videoId}&key=${apiKey}&textFormat=plainText&maxResults=100`;
  
  try {

    const response = await axios.get(`http://localhost:5001/api/transcript?videoId=${videoId}`);
    const transcript = response.data;

    console.log(transcript);

    const transcriptText = transcript.map((item) => item.text);

    // Fetch comments from YouTube
    const commentsResponse = await axios.get(commentsUrl);
    const comments = commentsResponse.data.items.map(item => item.snippet.topLevelComment.snippet.textDisplay);

    // console.log(comments);

    // Now analyze these comments with OpenAI (handled on your server)
    const analysisResult = await analyzeComments(comments);
    // console.log("result: ", analysisResult)

    // return transcriptText;
    return analysisResult;
  } catch (error) {
    console.error('Error fetching comments or analyzing:', error);
    throw error;
  }
}

export async function fetchTranscript(videoUrl) {
  const videoId = extractVideoId(videoUrl);

  try {
    const response = await axios.get(`http://localhost:5001/api/transcript?videoId=${videoId}`);
    const transcript = response.data;
    const transcriptText = transcript.map((item) => item.text).join(' ');

    return transcriptText;
  } catch (error) {
    console.error('Error fetching transcript:', error);
    throw error;
  }
}

async function analyzeTranscript(transcript) {
  try {
    const openai = new OpenAI({
      apiKey: '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',
      dangerouslyAllowBrowser: true
    });

    const completion = await openai.chat.completions.create({
      messages: [
        { "role": "system", "content": "You are a helpful assistant." },
        { "role": "user", "content": "Following this message is a transcript of an educational YouTube video. \
        Please provide 5 comprehension questions to test the user's comprehension. \n" + transcript }
        // { "role": "user", "content": "Please provide a summary of the following YouTube video transcript:\n" + transcript }
      ],
      model: "gpt-3.5-turbo",
    });

    const summary = completion.choices[0].message.content;
    return summary;
  } catch (error) {
    console.error('Error analyzing transcript with OpenAI:', error);
    throw error;
  }
}

export async function fetchTranscriptAndAnalyze(videoUrl) {
  try {
    const transcriptText = await fetchTranscript(videoUrl);
    const analysisResult = await analyzeTranscript(transcriptText);
    return analysisResult;
  } catch (error) {
    console.error('Error fetching or analyzing transcript:', error);
    throw error;
  }
}

function extractVideoId(url) {
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
  return match && match[1];
}