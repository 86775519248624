import React, { useState } from 'react';
import TopBar from './TopBar';
import Footer from './Footer';
import './ChannelList.css';
import { db, auth } from '../firebase';
import { fetchCommentsAndAnalyze } from '../api/commentsllm1';
import './Lab.css';
import { serverTimestamp, addDoc, collection } from 'firebase/firestore';

const Lab = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [analysisResult, setAnalysisResult] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Added isLoading state

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading to true when the analysis starts

    const reviewCollectionRef = collection(db, "log");
    await addDoc(reviewCollectionRef, {
      action: "tried analyzing video",
      video: searchQuery,
      user: auth.currentUser.uid,
      timestamp: serverTimestamp(),
    });

    try {
      const response = await fetchCommentsAndAnalyze(searchQuery);
      setAnalysisResult(response);
    } catch (error) {
      alert('Error fetching analysis. Maybe the URL is invalid or the site is messed up :(');
      // setAnalysisResult({summary: "Error. Please try again later.", rating: ""});
      // console.error('Error fetching analysis:', error);
    } finally {
      setIsLoading(false);
    }
  };

  function getEmbedUrl(youtubeUrl) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = youtubeUrl.match(regExp);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    } else {
      // Handle error
      return 'Error: Invalid YouTube URL';
    }
  }

  function getRatingFromText(s) {
    // Find the index of the first occurrence of "**"
    const index = s.indexOf("**");
    // If "**" is found and there is at least one character after it, return that character
    if (index !== -1 && s.length > index + 2) {
      return s[index + 2];
    } else {
      // Return null if "**" is not found or there is no character after it
      return null;
    }
  }


  return (
    <div className="channelList">
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />

      <h1>Lab (experiments in progress)</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="YouTube Video URL"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button type="submit">Analyze</button>
      </form>

      {isLoading ? (
        <p>Loading analysis...</p> // Display a loading text when isLoading is true
      ) : (
      analysisResult &&
        <div className="analysisResult">
          <div>
            <iframe
              width="600"
              height="400"
              src={getEmbedUrl(searchQuery)} // Here analysisResult is the embed URL
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
          </div>

          <br></br>

          <div className="vidText">

          <div className="vidRating">{getRatingFromText(analysisResult.rating)} </div>


          <div className="vidSumm">
            {analysisResult.summary}
          </div>

          </div>
          
          <br></br>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Lab;
