import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp, addDoc, collection } from 'firebase/firestore';
import TopBar from './TopBar';
import './ChannelPage.css';
import { getYoutubeChannelInfo } from '../api/youtube1';
import Footer from './Footer';
import Review from './Review';

const ChannelPage = () => {
  const { channelName } = useParams();
  const [channelData, setChannelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [rating, setRating] = useState('');
  const [reviewText, setReviewText] = useState('');
  const navigate = useNavigate();

  // Function to fetch the display name for a given user ID
  const fetchDisplayName = async (userId) => {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? userSnap.data().displayName : null;
  };

  useEffect(() => {
    const fetchChannelData = async () => {
      const channelDocRef = doc(db, "channels", channelName.toLowerCase());
      const channelDoc = await getDoc(channelDocRef);

      if (channelDoc.exists()) {
        let channelInfo = channelDoc.data();
        // Fetch display names for each review
        const reviewsWithNames = await Promise.all(channelInfo.reviews.map(async (review) => {
          const displayName = await fetchDisplayName(review.userId);
          return { ...review, displayName }; // Combine review with displayName
        }));
        setChannelData({ ...channelInfo, reviews: reviewsWithNames });
      }
      setIsLoading(false);
    };

    fetchChannelData();
  }, [channelName]);

  const addChannel = async () => {
    setIsLoading(true); // Start loading
    try {
      const reviewCollectionRef = collection(db, "log");
      await addDoc(reviewCollectionRef, {
        action: "tried adding channel",
        channel: channelName,
        user: auth.currentUser.uid,
        timestamp: serverTimestamp(),
      });

      // Attempt to fetch channel info from the YouTube API
      const channelInfo = await getYoutubeChannelInfo(channelName);
      if (!channelInfo) {
        alert("Channel not found on YouTube :( Make sure you have the correct channel title -- you have to add by the exact full channel name for now. Or maybe the site's messed up right now.");
        setIsLoading(false);
        return;
      }

      // Prepare the document data
      const channelData = {
        uid: channelName.toLowerCase(),
        name: channelName,
        reviews: [],
        numberOfReviews: 0,
        userAdded: auth.currentUser.uid,
        ratingsCount: {
          S: 0,
          A: 0,
          B: 0,
          C: 0,
          D: 0,
          F: 0,
        },
        timestamp: serverTimestamp(), // Add a timestamp here
        ...channelInfo // Spread other fetched info (total views, subscriber count, etc.)
      };

      // Add the new channel to Firestore
      const channelDocRef = doc(db, "channels", channelName.toLowerCase());
      await setDoc(channelDocRef, channelData);

      // Update local state with the new channel data
      setChannelData(channelData);

    } catch (err) {
      console.error("Error adding channel:", err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleAddReview = () => {
    // Check if the user already reviewed this channel
    const existingReview = channelData.reviews.find(review => review.userId === auth?.currentUser?.uid);
    if (existingReview) {
      // If there is an existing review, populate the form for editing
      setRating(existingReview.rating);
      setReviewText(existingReview.text);
    } else {
      // If no existing review, clear the form
      setRating('');
      setReviewText('');
    }
    setShowReviewPopup(true); // Show the popup for adding/editing a review
  };

  // Close the review popup
  const closeReviewPopup = () => {
    setShowReviewPopup(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const submitReview = async () => {
    if (!rating || !reviewText) {
      alert("Please fill in all fields");
      return;
    }

    const userId = auth.currentUser ? auth.currentUser.uid : "anonymous";
    const existingReviewIndex = channelData.reviews.findIndex(review => review.userId === userId);
    const isEdit = existingReviewIndex !== -1 && auth.currentUser;
    const oldReviewRating = isEdit ? channelData.reviews[existingReviewIndex].rating : null;

    // using ^^ instead of below so that anon can post reviews for now. 

    // const existingReviewIndex = channelData.reviews.findIndex(review => review.userId === auth.currentUser.uid);
    // const isEdit = existingReviewIndex !== -1;
    // const oldReviewRating = isEdit ? channelData.reviews[existingReviewIndex].rating : null;

    // Prepare the updated reviews array for the channel
    let updatedReviews = [...channelData.reviews];
    if (isEdit) {
      updatedReviews[existingReviewIndex] = { ...updatedReviews[existingReviewIndex], rating, text: reviewText };
    } else {
      // updatedReviews.push({ userId: auth.currentUser.uid, rating, text: reviewText }); 
      // same ^^ and below
      updatedReviews.push({ userId, rating, text: reviewText });
    }

    // Update the channel's ratingsCount
    let updatedChannelRatingsCount = { ...channelData.ratingsCount, [rating]: (channelData.ratingsCount[rating] || 0) + 1 };
    if (isEdit && oldReviewRating) {
      updatedChannelRatingsCount[oldReviewRating] = Math.max(0, (updatedChannelRatingsCount[oldReviewRating] || 0) - 1);
    }

    // Update channel document in Firestore
    const channelDocRef = doc(db, "channels", channelName.toLowerCase());
    await updateDoc(channelDocRef, {
      reviews: updatedReviews,
      ratingsCount: updatedChannelRatingsCount,
      numberOfReviews: updatedReviews.length,
    });

    if (auth.currentUser) {


      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      let userData = userDocSnap.data();
      let userRatingsCount = userData.ratingsCount || { S: 0, A: 0, B: 0, C: 0, D: 0, F: 0 };
      let userReviews = userData.reviews || [];
      let userNumRated = userData.numRated || 0;

      userRatingsCount[rating] = (userRatingsCount[rating] || 0) + 1;
      userNumRated = userNumRated + 1;
      if (isEdit && oldReviewRating) {
        userRatingsCount[oldReviewRating] = Math.max(0, (userRatingsCount[oldReviewRating] || 0) - 1);
        userNumRated = userNumRated - 1;
      }

      // Handle ratingsCount logic as before

      // Check if there's an existing review for this channel by this user
      const existingUserReviewIndex = userReviews.findIndex(review => review.channelName === channelName.toLowerCase());

      // Prepare the review object
      const reviewToUpdateOrAdd = {
        channelName: channelName.toLowerCase(),
        rating,
        text: reviewText,
      };

      // Update or add the review
      if (isEdit) {
        userReviews[existingUserReviewIndex] = reviewToUpdateOrAdd;
      } else {
        userReviews.push(reviewToUpdateOrAdd);
      }

      // Update Firestore document for user
      await updateDoc(userDocRef, {
        ratingsCount: userRatingsCount,
        reviews: userReviews,
        numRated: userNumRated
      });

    }

    const reviewCollectionRef = collection(db, "reviews");
    await addDoc(reviewCollectionRef, {
      channel: channelName,
      // user: auth.currentUser.uid,
      user: userId,
      text: reviewText,
      rating,
      timestamp: serverTimestamp(),
    });

    // Update local state and close popup
    setChannelData(prevState => ({
      ...prevState,
      reviews: updatedReviews,
      ratingsCount: updatedChannelRatingsCount,
      numberOfReviews: updatedReviews.length
    }));

    closeReviewPopup();
  };

  const findMyReview = () => {
    try {
      // Find the review made by the current user
      const myReview = channelData.reviews.find(review => review.userId === auth.currentUser.uid);

      if (myReview) {
        // Review found, return the rating and text
        return { rating: myReview.rating, text: myReview.text };
      } else {
        // No review found
        return { rating: null, text: "You haven't rated this channel yet." };
      }
    }
    catch (err) {
      return;
    }
  };

  return (
    <div className="channelpage">
      <TopBar
        title="S-TIER STUFF"
        // profileImageUrl={auth?.currentUser?.photoURL}
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />
      <div className="channel-all">
        {channelData ? (
          <div className="channel-div-container">
            <div className="channel-content">
              <div className="channel-details">
                <div className="profile-and-name">
                  <div className="channel-name">
                    {channelData?.actualTitle}
                  </div>
                  {/* <img src={channelData?.picture} alt={`${channelData?.name} profile`} /> */}
                  <img src={channelData?.profilePictureUrl ? channelData?.profilePictureUrl : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt={`${channelData?.actualTitle} profile`} />
                  <div className="youtubeImg">
                    <a href={`https://youtube.com/${channelData?.customUrl}`} title="Go to YouTube Channel" target="_blank" rel="noopener noreferrer">
                      <img src={'https://cdn-icons-png.flaticon.com/256/1384/1384060.png'}></img>
                    </a>
                  </div>
                </div>
                <div className="channel-subtitle">channel</div>

                <div className="channel-description">{channelData?.description}</div>
              </div>
              <div className="channel-stats">
                <div className="stat-item"><strong>Total Views:</strong> {channelData?.totalViews}</div>
                <div className="stat-item"><strong>Subscriber Count:</strong> {channelData?.subscriberCount}</div>
                <div className="stat-item"><strong>Video Count:</strong> {channelData?.videoCount}</div>
                <div className="stat-item"><strong>Channel Created:</strong> {new Date(channelData?.creationDate).toLocaleDateString()}</div>
                {/* Placeholder for additional data components */}

                <div>
                  <p>Number of Reviews: {channelData.numberOfReviews}</p>
                </div>

                <div className="ratings-distribution">
                  {
                    ['S', 'A', 'B', 'C', 'D', 'F'].map((key) => {
                      const totalRatings = Object.values(channelData?.ratingsCount || {}).reduce((acc, value) => acc + value, 0);
                      const ratingValue = channelData?.ratingsCount[key] || 0;
                      const percentage = totalRatings > 0 ? (ratingValue / totalRatings) * 100 : 0;

                      return (
                        <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ width: '20px', marginRight: '10px' }}>{key}</span>
                          <div style={{ background: '#e0e0e0', width: '100%', marginRight: '10px', display: 'flex', height: '20px' }}>
                            <div style={{ background: '#225560', width: `${percentage + 1}%` }}></div>
                          </div>
                          <span>{ratingValue}</span>
                        </div>
                      );
                    })
                  }
                </div>
                <div>
                  {/* Dynamically display the user's review or a prompt to add/edit a review */}


                  {(() => {
                    // if (!auth.currentUser) {
                    //   return (
                    //     <div>
                    //       <br></br>
                    //       <button onClick={() => navigate('/login')}>Log in to review!</button>
                    //     </div>
                    //   );
                    // }

                    const review = findMyReview(); // Call findMyReview() here within the JSX
                    return review?.rating ? (
                      <div>
                        <p>Your rating: {review.rating}</p>
                        <button onClick={handleAddReview}>Edit Review</button>
                      </div>
                    ) : (
                      <div>
                        <br></br>
                        <button onClick={handleAddReview}>Add Review</button>
                      </div>
                    );
                  })()}

                </div>

                <hr></hr>

              </div>
            </div>

            <h2>Reviews</h2>

            <div className="reviews-section">
              {channelData.reviews.length > 0 ? (
                <ul className="reviews-list">
                  {channelData.reviews.map((review, index) => (
                    <Review
                      key={index}
                      rating={review.rating}
                      text={review.text}
                      channel={channelData.actualTitle} // Assuming channelName is available in this scope
                      user={review.displayName || 'Unknown User'}
                      linkTo={`/user/${review.userId}`}
                    />
                  ))}
                </ul>
              ) : (
                <p className="first-review">Be the first to add a review! You might win a prize...</p>
              )}
            </div>

          </div>

        ) : (
          <div className="newChannel">
            <h1>Channel: {channelName}</h1>

            {!auth.currentUser ? (
              <button onClick={() => navigate('/login')}>Log in to add channel!</button>
            ) : (
              <button onClick={addChannel}>Be the first to add this channel!</button>
            )}

            {/* <p>Channel not found.</p> */}

          </div>
        )}

      </div>

      {/* Add/Edit review popup */}
      {showReviewPopup && (
        <div className="review-popup">
          {/* <h3>Write a Review</h3> */}

          <h3 className="review-channel-description">Write a Review{!auth.currentUser ? " (anonymously...log in instead to save your personal reviews!)" : ""}</h3>


          <div className="rating-buttons">
            {["S", "A", "B", "C", "D", "F"].map((rate) => (
              <button
                key={rate}
                className={`rating-button ${rating === rate ? 'selected' : ''}`}
                onClick={() => setRating(rate)}
              >
                {rate}
              </button>
            ))}
          </div>

          <textarea
            placeholder="Write your review here"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
          <button onClick={submitReview}>Submit Review</button>
          <button onClick={closeReviewPopup}>Cancel</button>
        </div>
      )}

      <Footer />

    </div>
  );
};

export default ChannelPage;